<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="ID" prop="account">
                <a-input v-model="queryParam.account" placeholder="请输入ID" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="手机号" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入手机号" allow-clear/>
              </a-form-item>
            </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="昵称" prop="nickname">
                  <a-input v-model="queryParam.nickname" placeholder="请输入昵称" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="性别" prop="sex">
                  <a-select placeholder="请选择性别" v-model="queryParam.sex" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in dict.type.sys_user_sex" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="用户状态" prop="status">
                  <a-select placeholder="请选择用户状态" v-model="queryParam.status" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in dict.type.sys_enable_disable" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :sexOptions="dict.type.sys_user_sex"
        :isNoticeOptions="dict.type.sys_notice_status"
        :statusOptions="dict.type.sys_enable_disable"
        @ok="getList"
      />
      <!-- 重置密码 -->
      <ResetPwdModalForm
        ref="ResetPwdModalForm"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="sex" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_user_sex']" :value="record.sex"/>
        </span>
        <span slot="isNotice" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_notice_status']" :value="record.isNotice"/>
        </span>
        <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_enable_disable']" :value="record.status"/>
        </span>
        <span slot="avatar" slot-scope="text, record">
          <div v-if="record.avatar">
            <img style="width: 48px;height: 48px;" v-image-preview :src="record.avatar" >
          </div>
          <div v-else>-</div>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a v-if="record.status == 1" @click="disabledUser(record,1)" >
            禁用
          </a>
          <a v-if="record.status == 0" @click="disabledUser(record,2)" >
            解禁
          </a>
          <a-divider type="vertical" />
          <a @click.stop="chongzhiMima(record)">
            重置密码
          </a>
        </span>

      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageUser, listUser, delUser, disabledUser} from '@/api/biz/user'
import CreateForm from './modules/CreateForm'
import ResetPwdModalForm from './modules/ResetPwdModalForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'User',
  components: {
    CreateForm,
    ResetPwdModalForm
  },
  mixins: [tableMixin],
  dicts: ['sys_user_sex', 'sys_notice_status', 'sys_enable_disable'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,
        email: null,
        nickname: null,
        sex: null,
        invitationCode: null,
        isNotice: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'account',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '头像',
          dataIndex: 'avatar',
          align: 'center',
          scopedSlots: { customRender: 'avatar' }
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center',
          customRender: function (v) {
            if (v) {
              return v
            } else {
              return '-'
            }
          }
        },
        {
          title: '昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center',
          customRender: function (v) {
            if (v) {
              return v
            } else {
              return '-'
            }
          }
        },
        // {
        //   title: '性别',
        //   dataIndex: 'sex',
        //   scopedSlots: { customRender: 'sex' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '生日',
        //   dataIndex: 'birthday',
        //   ellipsis: true,
        //   align: 'center',
        //   customRender: function (v) {
        //     if (v) {
        //       return v
        //     } else {
        //       return '-'
        //     }
        //   }
        // },
        // {
        //   title: '邀请码',
        //   dataIndex: 'invitationCode',
        //   ellipsis: true,
        //   align: 'center',
        //   customRender: function (v) {
        //     if (v) {
        //       return v
        //     } else {
        //       return '-'
        //     }
        //   }
        // },
        // {
        //   title: '是否推送',
        //   dataIndex: 'isNotice',
        //   scopedSlots: { customRender: 'isNotice' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '用户状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    chongzhiMima(record) {
      this.$refs['ResetPwdModalForm'].openModal(record.id)
    },
    disabledUser(record, type) {
      var that = this
      const ids = record.id || this.ids
      if (record.id) {
        this.ids.push(record.id)
      }
      this.$confirm({
        title: type == 1 ? '确认禁用所选中数据?' : '确认解禁所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id, 'type': type }))
          return disabledUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                type == 1 ? '禁用成功' : '解禁成功',
                3
              )
            })
        },
        onCancel () {
          // 取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 查询用户信息列表 */
    getList () {
      this.loading = true
     pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        mobile: undefined,
        email: undefined,
        nickname: undefined,
        sex: undefined,
        invitationCode: undefined,
        isNotice: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/user/export', {
            ...that.queryParam
          }, `用户信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
