<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="跳转类型" prop="type">
                <a-select v-model="queryParam.type" placeholder="请输入跳转类型" allow-clear >
                  <a-select-option :value="0">不跳转</a-select-option>
                  <a-select-option :value="1">富文本</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="位置类型" prop="positionType">
                <a-select v-model="queryParam.positionType" placeholder="请输入位置类型" allow-clear >
                  <a-select-option :value="1">引导页</a-select-option>
                  <a-select-option :value="2">首页</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="是否启用" prop="isEnable">
                <a-select v-model="queryParam.isEnable" placeholder="请输入是否启用" allow-clear >
                  <a-select-option :value="0">否</a-select-option>
                  <a-select-option :value="1">是</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" >
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" >
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" >
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['user:swiper:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <img style="width: 48px;height: 48px;" v-image-preview :src="record.url" slot="url" slot-scope="text, record">
        <a-switch slot="isEnable" slot-scope="text, record" @click="updateSwiperState(record)" v-model="record.isEnable == 1">
          <a-icon type="check" slot="checkedChildren"/>
          <a-icon type="close" slot="unCheckedChildren"/>
        </a-switch>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical"  />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" >
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageSwiper,listSwiper, delSwiper,updateSwiper } from '@/api/user/swiper'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Swiper',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        type: null,
        positionType: null,
        url: null,
        isEnable: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '跳转类型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if(text == 0) {
              return '不跳转'
            } else if(text == 1) {
              return '富文本'
            }
          }
        },
        {
          title: '位置类型',
          dataIndex: 'positionType',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if(text == 1) {
              return '引导页'
            } else if(text == 2) {
              return '首页'
            }
          }
        },
        {
          title: '图片',
          dataIndex: 'url',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'url' }
        },
        {
          title: '是否启用',
          dataIndex: 'isEnable',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'isEnable' }
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /**
     * 启用/禁用
     */
    updateSwiperState(record) {
      console.log(record)
      const editRecordData = {
        id: record.id,
        isEnable: record.isEnable == 1 ? 0 : 1
      }
      updateSwiper(editRecordData).then(response => {
        // this.$message.success(
        //   '修改成功',
        //   3
        // )
        // this.open = false
        if(response.code == 20001) {
          record.useFlag = record.useFlag == 1? 0 : 1
        } else {

        }
        // this.$emit('ok')
      }).finally(() => {
        // this.submitLoading = false
      })
    },
    /** 查询轮播图列表 */
    getList () {
      this.loading = true
     pageSwiper(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: undefined,
        url: undefined,
        resourceId: undefined,
        deleted: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delSwiper(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/swiper/export', {
            ...that.queryParam
          }, `轮播图_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
