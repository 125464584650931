<!--
  简单增删改查管理页面
  @author GongLiHai
-->
<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <search v-if="config.search && config.search.length" :config="config.search" @search="search"></search>
      <!-- 操作 -->
      <tool-button v-if="config.button && config.button.length" :config="config.button" :loading="loading"
        :tableSelectRows="selectedRows" :tableSize="tableSize" :columns.sync="columns" @refresh="getList"
        @click="buttonClick"></tool-button>

      <slot
         name="curdSlot"></slot>
      <!-- 数据表格 -->
      <template v-if="config.table">
        <!-- 数据展示 -->
        <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
          :bordered="tableBordered">
          <!-- 表格列作用域插槽 -->
          <!-- col 为列配置, slotKey 为插槽名, record 为行数据 -->
          <template v-for="(col, slotKey) in tableColumScopedSlots" :slot="slotKey" slot-scope='text, record, index'>
            <col-pack :record="record" :text="text" :index="index" :col="col" :slotKey="slotKey"
              @colButtonClick="colButtonClick"></col-pack>
          </template>
        </a-table>
        <!-- 分页 -->
        <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="page.pageNum"
          :total="page.total" :page-size="page.pageSize" :showTotal="total => `共 ${page.total} 条`"
          @showSizeChange="onShowSizeChange" @change="changeSize" />
      </template>
      <!-- 表单 -->
      <crud-form v-if="config.form" ref="crudForm" :config="config.form" @submit="formSubmit" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import Search from "./search/Search.vue";
import ToolButton from "./button/ToolButton.vue";
import ColPack from "./table/ColPack.vue";
import CrudForm from "./form/CrudForm.vue";

import request from "@/utils/request.js";

import { tableMixin } from '@/store/table-mixin';

import { Log } from "./Log.js";

// 需要使用列插槽的列类型
const ColSoltTypes = ['switch', 'img', 'file', 'button', 'richText', 'datetime', 'tag', 'video', 'imgs', 'avatar', 'longText', 'calculate'];


export default {
  name: 'Crud',
  components: {
    Search, ToolButton, ColPack, CrudForm
  },
  props: {
    config: Object
  },
  mixins: [tableMixin],
  data() {
    return {
      // 表格数据
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      ids: [],
      // 表格加载状态
      loading: false,
      // 分页
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 表格列
      columns: [],
      // 查询条件缓存
      searchParams: {},
      firstOnload:false,
      otherParams:{

      }
    }
  },
  created() {
    this.createTableColums();
    // if(this.firstOnload) {
      this.getList();
    // }
  },
  computed: {
    // 返回 按钮类型的操作列 插槽与按钮配置对象
    tableColumScopedSlots() {
      const r = {};
      this.config.table.col.forEach((item, index) => {
        if (ColSoltTypes.includes(item.type)) {
          r[`COL_SLOT_${item.type}_${index}`] = item;
        }
      })
      return r
    },
  },
  methods: {
    /**
     * 查询条件点击
     * @param searchParams 查询条件
     */
    search(searchParams) {
      console.log("searchParams");
      console.log(searchParams);
      this.page.pageNum = 1;
      this.searchParams = {...searchParams ,...this.otherParams};
      this.getList();
    },

    openSearch(searchParams) {
      this.otherParams = searchParams;
    },
    /**
     * 操作按钮点击事件
     * @param buttonItem 按钮配置项
     * @param $event 点击事件
     */
    buttonClick(buttonItem, $event) {
      switch (buttonItem.event) {
        // 内置新增
        case "inner-add":
          this.$refs.crudForm.add();
          break;
        // 内置编辑
        case "inner-edit":
          this.$refs.crudForm.edit(this.selectedRows[0]);
          break;
        // 内置删除
        case "inner-delete":
          this.innerDelete();
          break;
        // 内置导出
        case "inner-export":
          this.innerExport();
        default:
          break;
      }
    },
    /**
     * 表格操作点击事件
     * @param col 列配置
     * @param rowData 行数据
     * @param $event 点击事件
     */
    colButtonClick(col, rowData, $event) {
      switch (col.event) {
        // 内置编辑
        case "inner-edit":
          this.$refs.crudForm.edit(rowData);
          break;
        // 内置删除
        case "inner-delete":
          this.innerDelete(rowData.id);
          break;
        default:
          this.$emit(col.event, col, rowData, event);
      }
    },
    // 配置列转换
    createTableColums() {
      this.config.table.col.forEach((col, index) => {
        this.columns.push({
          // 标题
          title: this.getTableColumTitle(col),
          // 属性key
          dataIndex: this.getTableColumDataIndex(col),
          // 自定义渲染函数
          customRender: this.getTableColumCustomRender(col),
          // 宽度
          width: this.getTableColumWidth(col),
          // 水平对齐
          align: this.getTableColumCenter(col),
          // 插槽
          scopedSlots: this.getTableColumScopedSlot(col, index)
        })
      })
    },
    // 表格列表头标题
    getTableColumTitle(col) {
      if (col.name) {
        return col.name;
      }
      switch (col.type) {
        case 'index':
          return "#";
        case 'button':
          return '操作';
        default:
          return col.field;
      }
    },
    // 表格列字段映射值
    getTableColumDataIndex(col) {
      if (col.field) {
        return col.field;
      }
    },
    // 表格列渲染函数
    getTableColumCustomRender(col) {
      if (col.fmt) {
        return col.fmt;
      }
      switch (col.type) {
        case 'index':
          return (t, r, index) => {
            return parseInt(index) + 1
          };
      }
    },
    // 表格列宽度
    getTableColumWidth(col) {
      if (col.width) {
        return col.width;
      }
      switch (col.type) {
        case 'index':
          return 60;
        case "switch":
          return 80;
        case "button":
          return 140;
        case "datetime":
          return 145;
        case "avatar":
          return 40;
        case "longText":
          return 150;
        case "img":
          return 80;
      }
    },
    // 表格列对齐方式
    getTableColumCenter(col) {
      if (col.align) {
        return col.align;
      }
      switch (col.type) {
        case 'index':
        case "switch":
        case "button":
        case "datetime":
        case "tag":
        case "avatar":
          return 'center';
        default:
          return 'left';
      }
    },
    // 表头列插槽
    getTableColumScopedSlot(col, index) {
      if (ColSoltTypes.includes(col.type)) {
        return { customRender: `COL_SLOT_${col.type}_${index}` }
      }
      return undefined;
    },
    /** 获取表格数据 */
    getList() {
      this.loading = true;
      const params = {
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };

      // 查询条件混入
      Object.keys(this.searchParams).forEach(key => {
        const value = this.searchParams[key];
        if (value != null && value != undefined && value !== '') {
          params[key] = value;
        }
      });

      request({
        url: this.config.table.api,
        method: 'get',
        params
      }).then(response => {
        this.list = response.data.records
        this.page.total = response.data.total
        this.loading = false;
        Log.info("数据表格", this.list)
      })
    },
    // 页大小改变
    onShowSizeChange(current, pageSize) {
      this.page.pageNum = 1;
      this.page.pageSize = pageSize
      this.getList()
    },
    // 页码改变
    changeSize(current, pageSize) {
      this.page.pageNum = current
      this.page.pageSize = pageSize
      this.getList()
    },
    // 选择行改变
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.$emit('changeSelect',this.selectedRows)
    },
    /**
     * 内置删除
     * @param id 单条 id, 多条取表格行选中数据
     */
    innerDelete(id) {
      const content = id ? '确认删除此条数据吗?' : `确认删除选中的 ${this.selectedRows.length} 条数据吗?`
      this.$confirm({
        title: '确认删除?',
        content,
        okType: 'danger',
        onOk: () => {
          // 单条 id, 多条取表格行选中
          const data = id ? [{ id }] : this.selectedRows.map(item => {
            return { id: item.id };
          });
          return request({
            url: this.config.table.deleteApi,
            method: 'post',
            data: data
          }).then(() => {
            this.onSelectChange([], []);
            this.getList();
            this.$message.success(
              '删除成功',
              3
            )
          });
        },
      })
    },
    /**
     * 内置导出
     */
    innerExport() {
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk: () => {
          this.download(this.config.table.exportApi, {
            ...this.queryParam
          }, `export_${new Date().getTime()}.xlsx`)
        }
      })
    },
    /**
     * 表单提交
     */
    formSubmit() {
      this.getList();
    }
  }
}
</script>
