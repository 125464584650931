<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="货物性质" prop="nature">
                <a-select v-model="queryParam.nature" placeholder="请输入货物性质" allow-clear>
                  <a-select-option :value="0">现货</a-select-option>
                  <a-select-option :value="1">非现货</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="货物类型" prop="type">
                <a-select v-model="queryParam.type" placeholder="请输入货物类型" allow-clear>
                  <a-select-option :value="0">供应</a-select-option>
                  <a-select-option :value="1">求购</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="货物名称" prop="goodName">
                <a-input v-model="queryParam.goodName" placeholder="请输入货物名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="审核状态" prop="status">
                <a-select v-model="queryParam.status" placeholder="请选择审核状态" allow-clear>
                  <a-select-option :value="0">待审核</a-select-option>
                  <a-select-option :value="1">通过</a-select-option>
                  <a-select-option :value="2">驳回</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="上架状态" prop="listingStatus">
                <a-select v-model="queryParam.listingStatus" placeholder="请选择上架状态" allow-clear>
                  <a-select-option :value="0">下架</a-select-option>
                  <a-select-option :value="1">上架</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:goods:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:goods:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:goods:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['user:goods:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :scroll="{ x: 1500 }"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        style="overflow: hidden;text-overflow: ellipsis;word-break: break-all;"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="config" slot-scope="text, record">
          标称电压(V):{{ record.nominalVoltage }}
          <br/>
          标称容量(Ah):{{ record.nominalCapacity }}
          <br/>
          单体内阻(MΩ):{{ record.internalResistance }}
          <br/>
          单体重量(KG):{{ record.individualWeight }}
          <br/>
          充电电压(V):{{ record.chargingVoltage }}
          <br/>
          放电电压(V):{{ record.dischargeVoltage }}
          <br/>
          持续放电倍率(C):{{ record.continuousDischargeRate }}
          <br/>
          瞬间放电倍率(C):{{ record.instantaneousDischargeRate }}
          <br/>
          长*宽*高(mm):{{ record.batteryLong }}*{{ record.batteryWidth }}*{{ record.batteryHeight }}
          <br/>
        </span>
        <a-switch slot="listingStatus" slot-scope="text, record" @click="updateGoodsState(record)" v-model="record.listingStatus == 1">
          <a-icon type="check" slot="checkedChildren"/>
          <a-icon type="close" slot="unCheckedChildren"/>
        </a-switch>
        <span slot="operation" slot-scope="text, record">
          <a-divider v-if="record.status == 0" type="vertical"  />
          <a v-if="record.status == 0" @click="$refs.createForm.handleUpdate(record, undefined)" >
            <a-icon type="edit" />审核
          </a>
          <a-divider type="vertical"  />
          <a @click="handleDelete(record)" >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageGoods,listGoods, delGoods,updateGoods } from '@/api/user/goods'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {updateMenu} from "@/api/user/menu";

export default {
  name: 'Goods',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        type: null,
        nature: null,
        goodName: null,
        goodNum: null,
        brandValue: null,
        batteryType: null,
        nominalVoltage: null,
        nominalCapacity: null,
        internalResistance: null,
        individualWeight: null,
        chargingVoltage: null,
        dischargeVoltage: null,
        continuousDischargeRate: null,
        instantaneousDischargeRate: null,
        batteryLong: null,
        batteryWidth: null,
        batteryHeight: null,
        imgUrls: null,
        videoUrls: null,
        supplyMinPrice: null,
        suppleMaxPrice: null,
        isTax: null,
        taxRate: null,
        content: null,
        userId: null,
        isHot: null,
        status: null,
        listingStatus: null,
        deleted: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '货物类型',
          dataIndex: 'type',
          align: 'center',
          customRender: function (type) {
            if(type == 0) {
              return '供应'
            } else if(type == 1) {
              return '求购'
            }
          }
        },
        {
          title: '货物性质',
          dataIndex: 'nature',
          align: 'center',
          customRender: function (type) {
            if(type == 0) {
              return '现货'
            } else if(type == 1) {
              return '非现货'
            }
          }
        },
        {
          title: '货物名称',
          dataIndex: 'goodName',
          align: 'center'
        },
        {
          title: '货物数量',
          dataIndex: 'goodNum',
          align: 'center'
        },
        {
          title: '货物品牌',
          dataIndex: 'brandValue',
          align: 'center'
        },
        {
          title: '电池类型',
          dataIndex: 'batteryType',
          align: 'center'
        },
        {
          title: '电池配置',
          dataIndex: 'config',
          align: 'center',
          scopedSlots: { customRender: 'config' }
        },
        // {
        //   title: '图片地址 多个以,拼接',
        //   dataIndex: 'imgUrls',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '视频地址 多个以,拼接',
        //   dataIndex: 'videoUrls',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '审核状态',
          dataIndex: 'status',
          align: 'center',
          customRender: function (type) {
            if(type == 0) {
              return '待审核'
            } else if(type == 1) {
              return '已通过'
            } else if(type == 2) {
              return '已驳回'
            }
          }
        },
        {
          title: '上架状态',
          dataIndex: 'listingStatus',
          align: 'center',
          scopedSlots: { customRender: 'listingStatus' }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    updateGoodsState(record) {
      const editRecordData = {
        id: record.id,
        status: record.status == 1 ? 0 : 1
      }
      updateGoods(editRecordData).then(response => {
        // this.$message.success(
        //   '修改成功',
        //   3
        // )
        // this.open = false
        if(response.code == 20001) {
          record.useFlag = record.useFlag == 1? 0 : 1
        } else {

        }
        // this.$emit('ok')
      }).finally(() => {
        // this.submitLoading = false
      })
    },
    /** 查询电池商品列表 */
    getList () {
      this.loading = true
     pageGoods(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: undefined,
        nature: undefined,
        goodName: undefined,
        goodNum: undefined,
        brandValue: undefined,
        batteryType: undefined,
        nominalVoltage: undefined,
        nominalCapacity: undefined,
        internalResistance: undefined,
        individualWeight: undefined,
        chargingVoltage: undefined,
        dischargeVoltage: undefined,
        continuousDischargeRate: undefined,
        instantaneousDischargeRate: undefined,
        batteryLong: undefined,
        batteryWidth: undefined,
        batteryHeight: undefined,
        imgUrls: undefined,
        videoUrls: undefined,
        supplyMinPrice: undefined,
        suppleMaxPrice: undefined,
        isTax: undefined,
        taxRate: undefined,
        content: undefined,
        userId: undefined,
        isHot: undefined,
        status: undefined,
        listingStatus: undefined,
        deleted: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delGoods(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/goods/export', {
            ...that.queryParam
          }, `电池商品_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
