import request from '@/utils/request'


// 查询电池商品列表
export function listGoods(query) {
  return request({
    url: '/user/goods/list',
    method: 'get',
    params: query
  })
}

// 查询电池商品分页
export function pageGoods(query) {
  return request({
    url: '/user/goods/page',
    method: 'get',
    params: query
  })
}

// 查询电池商品详细
export function getGoods(data) {
  return request({
    url: '/user/goods/detail',
    method: 'get',
    params: data
  })
}

// 新增电池商品
export function addGoods(data) {
  return request({
    url: '/user/goods/add',
    method: 'post',
    data: data
  })
}

// 修改电池商品
export function updateGoods(data) {
  return request({
    url: '/user/goods/edit',
    method: 'post',
    data: data
  })
}

// 删除电池商品
export function delGoods(data) {
  return request({
    url: '/user/goods/delete',
    method: 'post',
    data: data
  })
}
