<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="图片地址" prop="url" >
        <a-upload
          :customRequest="fileUploadRequest"
          list-type="picture-card"
          :showUploadList="false"
          :beforeUpload="beforeUpload"
          @change="handleChange">
          <img v-if="form.url" :src="form.url" alt="图片" style="height:104px;max-width:300px"/>

          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于50M,推荐规格:702*300 </span>
      </a-form-model-item>
      <a-form-model-item label="位置类型" prop="positionType" >
        <a-select v-model="form.positionType" placeholder="请输入位置类型">
          <a-select-option :value="1">引导页</a-select-option>
          <a-select-option :value="2">首页</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="跳转类型" prop="type" >
        <a-select v-model="form.type" placeholder="请输入跳转类型" @change="changeLinkTypeSelect">
          <a-select-option :value="0">不跳转</a-select-option>
          <a-select-option :value="1">富文本</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="跳转页面" prop="link" v-if="form.type == 1">
        <editor :editorValue="'editor'" v-model="form.link" placeholder="请输入内容" />
      </a-form-model-item>
      <a-form-model-item label="是否启用" prop="isEnable" >
        <a-radio-group v-model="form.isEnable" button-style="solid">
          <a-radio-button v-for="(d, index) in isEnableOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort" >
        <a-input-number v-model="form.sort" placeholder="请输入排序" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSwiper, addSwiper, updateSwiper } from '@/api/user/swiper'
import UploadFileToOSS from '@/utils/upload-file-to-oss'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      uploadLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        type: null,

        positionType: null,

        url: '',

        link: null,

        isEnable: 1,

        createTime: null,

        sort: null,

      },
      isEnableOptions:[{
        label: '启用',
        value: 1
      },
        {
          label: '禁用',
          value: 0
        }],
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: '跳转类型不能为空', trigger: 'blur' }
        ],
        positionType: [
          { required: true, message: '位置类型不能为空', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '图片地址不能为空', trigger: 'blur' }
        ],
        link: [
          { required: true, message: '跳转页面不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    /**
     * 上传图片
     * @param info
     */
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false
        this.form.url = info.file.originFileObj.ossUrl
      }
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if (file.size > 50 * 1024 * 1000) {
          this.$message.warning('图片大小不能超过50M')
          return reject(false)
        }

        var fileType = file.type
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片')
          return reject(false)
        }
        resolve(true)
      })
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true
      debugger
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          debugger
          const ossUrl = res[0].url
          fileInfo.file.ossUrl = ossUrl
          fileInfo.file.ossName = ossUrl
          fileInfo.onSuccess()
        }
      })
    },
    changeLinkTypeSelect(vale) {
      console.log(vale)
      if(vale == 0) {
        this.form.link = null
      } else {
        if(vale == 1) {

        } else {

        }
      }
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        url: null,
        positionType: null,
        link: null,
        isEnable: null,
        createTime: null,
        sort: null
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSwiper({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSwiper(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addSwiper(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
