<template>
  <crud :config="config"></crud>
</template>
<script>
import Crud from "@/components/crud/Crud.vue";

export default {
  components: {
    Crud
  },
  data() {
    return {
      config: {
        search: [
          { field: 'title', name: '标题' },
          { field: 'content', name: '内容' },
        ],
        button: [
          { name: '新增', color: 'primary', icon: 'plus', event: 'inner-add' },
          { name: '编辑', color: 'primary', icon: 'edit', event: 'inner-edit', single: true },
          { name: '删除', color: 'danger', icon: 'delete', event: 'inner-delete', multiple: true }
        ],
        table: {
          api: '/help/help/page',
          deleteApi: '/help/help/delete',
          col: [
            { field: 'title', name: '标题' },
            { field: 'content', name: '内容', type: 'richText' },
            { field: 'createTime', name: '创建时间', type: 'datetime' },
            {
              type: 'button', button: [
                { name: '编辑', event: 'inner-edit' },
                { name: '删除', event: 'inner-delete' }
              ]
            }
          ]
        },
        form: {
          addApi: '/help/help/add',
          editApi: '/help/help/edit',
          item: [
            { field: 'title', name: '标题', required: true },
            { field: 'content', name: '内容', type: 'richText', required: true },
          ]
        }
      }
    }
  }
}
</script>