<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="货物类型" prop="type" >
        {{ form.type == 0 ? '供应' : '求购' }}
      </a-form-model-item>
      <a-form-model-item label="货物性质" prop="nature" >
        {{ form.type == 0 ? '现货' : '非现货' }}
      </a-form-model-item>
      <a-form-model-item label="货物名称" prop="goodName" >
        {{ form.goodName }}
      </a-form-model-item>
      <a-form-model-item label="货物数量" prop="goodNum" >
        {{ form.goodNum }}
      </a-form-model-item>
      <a-form-model-item label="货物品牌" prop="brandValue" >
        {{ form.brandValue }}
      </a-form-model-item>
      <a-form-model-item label="电池类型" prop="batteryType" >
        {{ form.batteryType }}
      </a-form-model-item>
      <a-form-model-item label="电池配置" prop="config" >
        标称电压(V):{{ form.nominalVoltage }}
        <br/>
        标称容量(Ah):{{ form.nominalCapacity }}
        <br/>
        单体内阻(MΩ):{{ form.internalResistance }}
        <br/>
        单体重量(KG):{{ form.individualWeight }}
        <br/>
        充电电压(V):{{ form.chargingVoltage }}
        <br/>
        放电电压(V):{{ form.dischargeVoltage }}
        <br/>
        持续放电倍率(C):{{ form.continuousDischargeRate }}
        <br/>
        瞬间放电倍率(C):{{ form.instantaneousDischargeRate }}
        <br/>
        长*宽*高(mm):{{ form.batteryLong }}*{{ form.batteryWidth }}*{{ form.batteryHeight }}
        <br/>
      </a-form-model-item>
      <a-form-model-item label="商品图片" prop="imgUrls" >
        <img v-image-preview style="width: 80px;height: 80px;margin-right: 5px;margin-bottom: 5px" :src="item" :key="index" v-for="(item,index) in form.imgUrls.split(',')"/>
      </a-form-model-item>
      <a-form-model-item label="商品视频" prop="videoUrls" >
        <video :src="item" :key="index" v-for="(item,index) in form.videoUrls.split(',')" style="height: 80px" controls></video>
      </a-form-model-item>
      <a-form-model-item label="供应价格" prop="supplyMinPrice" >
        {{ form.supplyMinPrice }}--{{ form.suppleMaxPrice }}
      </a-form-model-item>
      <a-form-model-item label="是否含税" prop="isTax" >
        {{ form.isTax == 0 ? '含税' : '不含税' }}
      </a-form-model-item>
      <a-form-model-item label="税率" prop="taxRate" >
        {{ form.taxRate }}
      </a-form-model-item>
      <a-form-model-item label="说明文字" prop="content" >
        {{ form.content }}
      </a-form-model-item>
      <a-form-model-item label="是否热门" prop="isHot" >
        {{ form.isTax == 0 ? '否' : '是' }}
      </a-form-model-item>
      <a-form-model-item label="审核状态" prop="status" >
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in isStatusOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getGoods, addGoods, updateGoods } from '@/api/user/goods'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        type: null,

        nature: null,

        goodName: null,

        goodNum: null,

        brandValue: null,

        batteryType: null,

        nominalVoltage: null,

        nominalCapacity: null,

        internalResistance: null,

        individualWeight: null,

        chargingVoltage: null,

        dischargeVoltage: null,

        continuousDischargeRate: null,

        instantaneousDischargeRate: null,

        batteryLong: null,

        batteryWidth: null,

        batteryHeight: null,

        imgUrls: null,

        videoUrls: null,

        supplyMinPrice: null,

        suppleMaxPrice: null,

        isTax: null,

        taxRate: null,

        content: null,

        userId: null,

        isHot: null,

        status: 0,

        listingStatus: 0,

        createTime: null,

        deleted: null,

        remark: null,

      },
      isStatusOptions:[{
        label: '驳回',
        value: 2
      },{
        label: '通过',
        value: 1
      }],
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: '货物类型: 默认 0, 0-供应 1-求购不能为空', trigger: 'change' }
        ],
        nature: [
          { required: true, message: '货物性质: 默认 0, 0-现货 1-非现货不能为空', trigger: 'blur' }
        ],
        goodName: [
          { required: true, message: '货物名称不能为空', trigger: 'blur' }
        ],
        userId: [
          { required: true, message: '用户id不能为空', trigger: 'blur' }
        ],
        deleted: [
          { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        nature: null,
        goodName: null,
        goodNum: null,
        brandValue: null,
        batteryType: null,
        nominalVoltage: null,
        nominalCapacity: null,
        internalResistance: null,
        individualWeight: null,
        chargingVoltage: null,
        dischargeVoltage: null,
        continuousDischargeRate: null,
        instantaneousDischargeRate: null,
        batteryLong: null,
        batteryWidth: null,
        batteryHeight: null,
        imgUrls: null,
        videoUrls: null,
        supplyMinPrice: null,
        suppleMaxPrice: null,
        isTax: null,
        taxRate: null,
        content: null,
        userId: null,
        isHot: null,
        status: 0,
        listingStatus: 0,
        createTime: null,
        deleted: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGoods({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGoods(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGoods(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
