<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="cancel">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="分组" prop="configGroup" >
        <a-input v-model="form.configGroup" placeholder="请输入分组" />
      </a-form-model-item>
      <a-form-model-item label="变量名" prop="configName" >
        <a-input v-model="form.configName" placeholder="请输入变量名" />
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description" >
        <a-input v-model="form.description" placeholder="请输入描述" />
      </a-form-model-item>

      <a-form-model-item label="类型" prop="type" >
        <a-select v-model="form.type" placeholder="请选择类型">
          <a-select-option value="1">
           普通文本
          </a-select-option>
          <a-select-option value="2">
            富文本
          </a-select-option>
          <a-select-option value="3">
            数字
          </a-select-option>
          <a-select-option value="4">
            图片
          </a-select-option>

          <a-select-option value="5">
           文件
          </a-select-option>
<!--          <a-select-option value="6">-->
<!--            JSON-->
<!--          </a-select-option>-->
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="值" prop="configValue" v-if="form.type == 1">
        <a-input v-model="form.configValue" placeholder="请输入值" />
      </a-form-model-item>

      <a-form-model-item label="值" prop="configValue" v-if="form.type == 2">
        <Editor :editorValue="'editor'" v-model="form.configValue"></Editor>
      </a-form-model-item>
      <a-form-model-item label="值" prop="configValue" v-if="form.type == 3">
        <a-input v-model="form.configValue" type="number" placeholder="请输入值" />
<!--        <a-input-number v-model="form.configValue"></a-input-number>-->
      </a-form-model-item>
      <a-form-model-item label="值" prop="configValue" v-if="form.type == 4">
        <a-upload :customRequest='fileUploadRequest' list-type="picture-card"
                  :showUploadList="false"
                  :beforeUpload="beforeUpload"
                  @change="handleChange">
          <img v-if="form.configValue" :src="form.configValue" alt="图片" style="height:104px;max-width:300px"/>

          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于50M,推荐规格:800*800 </span>

      </a-form-model-item>
      <a-form-model-item label="值" prop="configValue" v-if="form.type == 5">
        <a-upload :customRequest='fileUploadRequests'
                  :multiple="true"
                  :file-list="fileList"
                  @change="handleChangeWenjian">
          <a-button> <a-icon type="upload" /> Upload </a-button>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getConfig, addConfig, updateConfig } from '@/api/config/config'
import Editor from '@/components/Editor'
import UploadFileToOSS from "@/utils/upload-file-to-oss";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      uploadLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        configGroup: null,

        configName: null,

        title: null,

        description: null,

        configValue: null,

        type: null,

        cateName: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      fileList: [],
      rules: {
        configName: [
          { required: true, message: '变量名不能为空', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '变量标题不能为空', trigger: 'blur' }
        ],
        configValue: [
          { required: true, message: '值不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '类型不能为空', trigger: 'change' }
        ],
        cateName: [
          { required: true, message: '分类名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    handleChangeWenjian(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });

      this.fileList = fileList;
      // console.log('fileList')
      // console.log(fileList)
     // let index = fileList[0].originFileObj.name.lastIndexOf(".");
     // let fileType = fileList[0].originFileObj.name.substr(index + 1);
     //  let indexIndex = fileList[0].originFileObj.ossUrl.lastIndexOf(".");
      this.form.configValue = fileList[0].originFileObj.ossUrl;
      console.log(this.form.configValue);
    },
    /**
     * 上传图片
     * @param info
     */
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.configValue = info.file.originFileObj.ossUrl;
      }
    },
    fileUploadRequests(fileInfo) {
      console.log(fileInfo)
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          debugger
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.fileList = [];
      this.form = {
        id: null,
        configGroup: null,
        configName: null,
        title: null,
        description: null,
        configValue: null,
        type: null,
        cateName: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getConfig({"id":id}).then(response => {
        this.form = response.data
        this.form.type = response.data.type + ''
        if(response.data.type == '5') {
          this.fileList = [{
            uid: '-1',
            name: response.data.configValue,
            status: 'done',
            url: response.data.configValue,
          }];
        }
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateConfig(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addConfig(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
