<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="数据类型" prop="dataType">-->
<!--                  <a-select v-model="queryParam.dataType" placeholder="请选择" allow-clear>-->
<!--                    <a-select-option value="1">用户</a-select-option>-->
<!--                    <a-select-option value="2">商品</a-select-option>-->
<!--                  </a-select>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" >-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" >-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <img style="width: 48px;height: 48px;" v-image-preview :src="record.reportImg" slot="url" slot-scope="text, record">
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical"  />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" >
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical"  />
          <a @click="handleDelete(record)" >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageReport,listReport, delReport } from '@/api/user/report'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Report',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        targetUserId: null,
        dataType: null,
        dataId: null,
        reportType: null,
        reportImg: null,
        reportContent: null,
        auditStatus: null,
        auditReason: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '举报人用户昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '被举报用户昵称',
          dataIndex: 'targetNickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '数据类型',
          dataIndex: 'dataType',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if(text == 1) {
              return '用户'
            } else if(text == 2) {
              return '商品'
            }
          }
        },
        {
          title: '数据名称',
          dataIndex: 'dataName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '举报类型',
          dataIndex: 'reportTypeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '举报图片地址',
          dataIndex: 'reportImg',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'reportImg' }
        },
        {
          title: '举报内容',
          dataIndex: 'reportContent',
          align: 'center'
        },
        {
          title: '审核状态',
          dataIndex: 'auditStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if(text == 0) {
              return '未审核'
            } else if(text == 1) {
              return '审核成功'
            } else if(text == 2) {
              return '审核失败'
            }
          }
        },
        {
          title: '审核原因(审核失败原因)',
          dataIndex: 'auditReason',
          ellipsis: true,
          align: 'center',
          customRender: function (v) {
            if (v) {
              return v
            } else {
              return '-'
            }
          }
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询举报列表 */
    getList () {
      this.loading = true
     pageReport(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        targetUserId: undefined,
        dataType: undefined,
        dataId: undefined,
        reportType: undefined,
        reportImg: undefined,
        reportContent: undefined,
        auditStatus: undefined,
        auditReason: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delReport(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/report/export', {
            ...that.queryParam
          }, `举报_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
