<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="群名称" prop="groupName">
                <a-input v-model="queryParam.groupName" placeholder="请输入群名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="群暗号" prop="groupCypher">
                <a-input v-model="queryParam.groupCypher" placeholder="请输入群暗号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:group:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:group:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:group:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['user:group:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />

      <group-user
        ref="groupUser"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="startTime" slot-scope="text, record">
          {{ parseTime(record.startTime) }}
        </span>
        <span slot="pauseTime" slot-scope="text, record">
          {{ parseTime(record.pauseTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <img style="width: 48px;height: 48px;" v-image-preview :src="record.groupAvatar" slot="groupAvatar" slot-scope="text, record">
        <span slot="operation" slot-scope="text, record">
          <a slot="groupUser" @click="$refs.groupUser.open(record)" >群组成员</a>
<!--          <a-divider type="vertical" v-hasPermi="['user:group:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['user:group:edit']">-->
<!--            <a-icon type="edit" />修改-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['user:group:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['user:group:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageGroup,listGroup, delGroup } from '@/api/user/group'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import GroupUser from './modules/GroupUser'

export default {
  name: 'Group',
  components: {
    GroupUser,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        groupName: null,
        groupCypher: null,
        groupAvatar: null,
        about: null,
        imGroupId: null,
        startTime: null,
        isPause: null,
        pauseTime: null,
        takeTime: null,
        status: null,
        sort: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '组长',
          dataIndex: 'nickname',
          align: 'center'
        },
        {
          title: '群名称',
          dataIndex: 'groupName',
          align: 'center'
        },
        {
          title: '群暗号',
          dataIndex: 'groupCypher',
          align: 'center'
        },
        {
          title: '群头像',
          dataIndex: 'groupAvatar',
          align: 'center',
          scopedSlots: { customRender: 'groupAvatar' }
        },
        {
          title: '简介',
          dataIndex: 'about',
          align: 'center'
        },
        {
          title: '是否暂停',
          dataIndex: 'isPause',
          align: 'center',
          customRender: function (status) {
            if(status == 0) {
              return '否'
            } else if(status == 1) {
              return '是'
            }
          }
        },
        {
          title: '用时(min)',
          dataIndex: 'takeTime',
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          customRender: function (status) {
            if(status == 0) {
              return '进行中'
            } else if(status == 1) {
              return '已结束'
            }
          }
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询群组列表 */
    getList () {
      this.loading = true
     pageGroup(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        groupName: undefined,
        groupCypher: undefined,
        groupAvatar: undefined,
        about: undefined,
        imGroupId: undefined,
        startTime: undefined,
        isPause: undefined,
        pauseTime: undefined,
        takeTime: undefined,
        status: undefined,
        sort: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delGroup(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/group/export', {
            ...that.queryParam
          }, `群组_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
